import { useRef, useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Label,
  LabelList,
  Cell,
  ReferenceLine,
  Tooltip as ChartTooltip,
} from 'recharts';
import { round } from 'lodash';
import {
  Box,
  Typography,
  Unstable_Grid2 as Grid,
  Tabs,
  Tab,
  IconButton,
  Tooltip,
} from '@mui/material';
import Gauge from './GaugeGraph';
import {
  NICounter,
  arcSegments,
  renderEventGraphs,
  featureNames,
  barGraphColorMapping,
  CustomLegend,
  CustomTooltip,
} from '../../../components/organGraph/common';
import ThermometerGraph from '../../../components/organGraph/thermometer';
import { idealHandDataCelsius, idealHandDataFahrenheit } from '../../../IdealData';
import LoadSpinner from '../../../components/LoadSpinner';
import HelpIcon from '@mui/icons-material/Help';

const HandTempBarChart = ({ temperatureSetting, data }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        width={500}
        height={300}
        data={data}
        barGap={25}
        barWidth={45}
        margin={{
          top: 5,
          right: 100,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="title" tick={false} />
        <YAxis
          dx={-7}
          allowDecimals={true}
          style={{ textAnchor: 'middle' }}
          domain={temperatureSetting === 'fahrenheit' ? [67, 97] : [20, 37]}
        >
          <Label
            value={temperatureSetting === 'fahrenheit' ? 'Degrees °F' : 'Degrees °C'}
            position={'insideLeft'}
            style={{
              fill: 'rgba(0, 0, 0, 0.87)',
              textAnchor: 'middle',
            }}
            angle={-90}
          />
        </YAxis>
        <Bar dataKey={'value'} label="Label" barSize={40}>
          {featureNames.map((featureName, index) => (
            <Cell key={`cell-${index}`} fill={barGraphColorMapping[featureName]} />
          ))}
          <LabelList
            dataKey={'value'}
            position="top"
            formatter={(val) => round(val, 2)}
            style={{ fontSize: '14px' }}
          />
        </Bar>
        <ReferenceLine
          y={temperatureSetting === 'fahrenheit' ? 97 : 36.1}
          stroke="grey"
          strokeDasharray="3 3"
          ifOverflow="visible"
        >
          <Label
            value={`Max. Ideal (${temperatureSetting === 'fahrenheit' ? 97 : 36.1})`}
            position={'right'}
            style={{
              fontSize: '12px',
            }}
          />
        </ReferenceLine>
        <ReferenceLine
          y={temperatureSetting === 'fahrenheit' ? 94 : 34.4}
          stroke="grey"
          strokeDasharray="3 3"
        >
          <Label
            value={`Min. Ideal (${temperatureSetting === 'fahrenheit' ? 94 : 34.4})`}
            position={'right'}
            style={{
              fontSize: '12px',
            }}
          />
        </ReferenceLine>

        <Legend content={<CustomLegend />} />
        {/* <ChartTooltip
          content={<CustomTooltip type={temperatureSetting === 'fahrenheit' ? 'HandF' : 'HandC'} />}
          cursor={{ fill: 'rgb(128,128,128, 0.2)' }}
        /> */}
      </BarChart>
    </ResponsiveContainer>
  );
};

const tabStyle = {
  borderColor: 'black',
  borderStyle: 'solid',
  color: 'black',
};

const HandTempGraph = ({ session, loading }) => {
  let tempIndex = session?.computed_data.temperature_resilence_index * 100;
  const measurementValue = tempIndex.toFixed(2);
  const eventGraphRef = useRef();
  const maxRange = 0.6;
  const minRange = 0.4;
  const [temperatureSetting, setTemperatureSetting] = useState('fahrenheit');
  const [valueC, setValueC] = useState([]);
  const [valueF, setValueF] = useState([]);

  function fahrenheitToCelsius(fahrenheit) {
    return ((fahrenheit - 32) * 5) / 9;
  }

  useEffect(() => {
    if (!eventGraphRef.current) return;
    renderEventGraphs(measurementValue, eventGraphRef, 'handTemp');
    const tempF = [
      {
        title: 'Eyes Open BL',
        value: session?.raw_data.temperature.eyes_open_baseline.toFixed(2),
      },
      {
        title: 'Eyes Closed BL',
        value: session?.raw_data.temperature.eyes_closed_baseline.toFixed(2),
      },
      {
        title: 'Math Test',
        value: session?.raw_data.temperature.math_task.toFixed(2),
      },
      {
        title: 'Recovery 1',
        value: session?.raw_data.temperature.recovery_1.toFixed(2),
      },
      {
        title: 'Sounds',
        value: session?.raw_data.temperature.distracting_sounds_task.toFixed(2),
      },
      {
        title: 'Recovery 2',
        value: session?.raw_data.temperature.recovery_2.toFixed(2),
      },
      {
        title: 'HRV Task',
        value: session?.raw_data.temperature.hrv_task.toFixed(2),
      },
      {
        title: 'Recovery 3',
        value: session?.raw_data.temperature.recovery_3.toFixed(2),
      },
    ];

    const tempC = [
      {
        title: 'Eyes Open BL',
        value: fahrenheitToCelsius(session?.raw_data.temperature.eyes_open_baseline),
      },
      {
        title: 'Eyes Closed BL',
        value: fahrenheitToCelsius(session?.raw_data.temperature.eyes_closed_baseline),
      },
      {
        title: 'Math Test',
        value: fahrenheitToCelsius(session?.raw_data.temperature.math_task),
      },
      {
        title: 'Recovery 1',
        value: fahrenheitToCelsius(session?.raw_data.temperature.recovery_1),
      },
      {
        title: 'Sounds',
        value: fahrenheitToCelsius(session?.raw_data.temperature.distracting_sounds_task),
      },
      {
        title: 'Recovery 2',
        value: fahrenheitToCelsius(session?.raw_data.temperature.recovery_2),
      },
      {
        title: 'HRV Task',
        value: fahrenheitToCelsius(session?.raw_data.temperature.hrv_task),
      },
      {
        title: 'Recovery 3',
        value: fahrenheitToCelsius(session?.raw_data.temperature.recovery_3),
      },
    ];

    setValueF(tempF);
    setValueC(tempC);
  }, []);

  return (
    <>
      {loading ? (
        <LoadSpinner />
      ) : (
        <>
          <Grid container justifyContent="space-between" spacing={2} sx={{ mt: 2 }}>
            <Grid xs={8}>
              <Typography variant="h5" style={{ fontWeight: 800 }}>
                Temperature Resilience Index
              </Typography>
              <Box marginTop="0.25rem">
                <NICounter niValue={measurementValue} />
              </Box>
            </Grid>
            <Grid xs={3}>
              <Tabs
                value={temperatureSetting}
                style={{ width: '100%' }}
                variant="fullWidth"
                onChange={(ev, value) => setTemperatureSetting(value)}
              >
                <Tab
                  label="°F"
                  value="fahrenheit"
                  style={{
                    ...tabStyle,
                    borderWidth: '1px',
                    backgroundColor: temperatureSetting === 'fahrenheit' ? '#eeeeee' : 'white',
                    borderRadius: '4px 0 0 4px',
                  }}
                />
                <Tab
                  label="°C"
                  value="celsius"
                  style={{
                    ...tabStyle,
                    borderRadius: '0 4px 4px 0',
                    borderWidth: '1px 1px 1px 0',
                    backgroundColor: temperatureSetting === 'celsius' ? '#eeeeee' : 'white',
                  }}
                />
              </Tabs>
            </Grid>
            <Grid xs={1}>
              <a
                href="https://youtu.be/VYJfKlpc3fc"
                rel="noreferrer"
                target="_blank"
              >
                <Tooltip title="Click to view the video tutorial.">
                  <IconButton color="primary" size="large">
                    <HelpIcon fontSize="inherit"/>
                  </IconButton>
                </Tooltip>
              </a>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid xs={12} md={6}>
              <Gauge
                min={0}
                max={100}
                value={measurementValue}
                maxAngle={90}
                minAngle={-90}
                tickCount={6}
                arcSegments={arcSegments}
                minRangeValue={minRange}
                maxRangeValue={maxRange}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <ThermometerGraph eventGraphRef={eventGraphRef} />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid xs={12} md={6}>
              <Typography align="center" variant={'h5'} style={{ fontStyle: 'italic' }}>
                IDEAL Hand Temp (
                {temperatureSetting === 'fahrenheit' ? '94°-97° F' : '34.4°-36.1° C'})
              </Typography>
              <HandTempBarChart
                temperatureSetting={temperatureSetting}
                data={
                  temperatureSetting === 'fahrenheit'
                    ? idealHandDataFahrenheit
                    : idealHandDataCelsius
                }
              />
            </Grid>
            <Grid xs={12} md={6}>
              <Typography align="center" variant={'h5'} style={{ fontStyle: 'italic' }}>
                YOUR Hand Temp -{' '}
                {temperatureSetting === 'fahrenheit'
                  ? `${session?.computed_data.temperature_average.toFixed(2)}° F`
                  : `${fahrenheitToCelsius(session?.computed_data.temperature_average).toFixed(
                      2
                    )}° C`}
              </Typography>
              <HandTempBarChart
                temperatureSetting={temperatureSetting}
                data={temperatureSetting === 'fahrenheit' ? valueF : valueC}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default HandTempGraph;
