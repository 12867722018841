import { useRef, useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import { round } from 'lodash';
import {
  Box,
  Typography,
  Unstable_Grid2 as Grid,
  Stack,
  IconButton,
  Tooltip,
} from '@mui/material';
import Gauge from './GaugeGraph';
import {
  NICounter,
  arcSegments,
  renderEventGraphs,
  barGraphColorMapping,
  featureNames,
  CustomLegend,
} from '../../../components/organGraph/common';
import EEGGraphic from '../../../components/organGraph/Brain';
import { idealBrainScoreData } from '../../../IdealData';
import LoadSpinner from '../../../components/LoadSpinner';
import HelpIcon from '@mui/icons-material/Help';

const CustomTooltip = ({ payload }) => {
  const xAxisLabel =
    payload && payload.length > 0 && payload[0]?.payload && payload[0]?.payload.featureName
      ? payload[0]?.payload.featureName
      : '';

  return (
    <Box
      style={{
        backgroundColor: 'white',
        padding: '1rem',
        outline: '1px solid black',
      }}
      display="flex"
      flexDirection="column"
      alignItems="left"
      justifyContent="left"
      maxWidth="18rem"
      minWidth="18rem"
    >
      <Typography variant="h6">{xAxisLabel}</Typography>
      {payload && payload.length > 0 ? (
        <Box mt="0.75rem" display="flex" flexDirection="column" alignItems="flex-start">
          {payload.map((payloadObj, index) => {
            return (
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                key={`tooltip-label-${index}`}
                marginBottom={index + 1 === payload.length ? 'auto' : '0.5rem'}
              >
                <Typography variant="body1" color={payloadObj.color}>
                  {payloadObj.name}:
                </Typography>
                <Typography variant="body1" color={payloadObj.color}>
                  {payloadObj.value}%
                </Typography>
              </Box>
            );
          })}
        </Box>
      ) : (
        <Box mt="0.75rem"></Box>
      )}
    </Box>
  );
};

const BrainScoreBarChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        width={600}
        height={300}
        data={data}
        barGap={0}
        barCategoryGap={2}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <Legend content={<CustomLegend />} />

        <XAxis
          dataKey="featureName"
          
        />
        <YAxis
          dx={-10}
          type="number"
          ticks={[0, 10, 20, 30, 40]}
          tickCount={5}
          interval={0}
          style={{ textAnchor: 'middle' }}
        />
        {featureNames.map((featureName) => (
          <Bar dataKey={featureName} fill={barGraphColorMapping[featureName]} key={featureName}>
            <LabelList
              dataKey={featureName}
              position="top"
              style={{ fontSize: '14px' }}
              formatter={(val) => round(val)}
            />
          </Bar>
        ))}
        {/* <ChartTooltip content={<CustomTooltip />} cursor={{ fill: 'rgb(128,128,128, 0.2)' }} /> */}
      </BarChart>
    </ResponsiveContainer>
  );
};

const BrainScoreGraph = ({ session, loading }) => {
  let measurementValue = (session?.computed_data.overall_brain_score * 100).toFixed(2);
  let brainScoreData = {
    overallBrainScore: measurementValue,
    theta_average_left: session?.computed_data.theta_left_average.toFixed(2),
    theta_average_right: session?.computed_data.theta_right_average.toFixed(2),
    alpha_average_left: session?.computed_data.alpha_left_average.toFixed(2),
    alpha_average_right: session?.computed_data.alpha_right_average.toFixed(2),
    beta_average_left: session?.computed_data.beta_left_average.toFixed(2),
    beta_average_right: session?.computed_data.beta_right_average.toFixed(2),
    smr_average_left: session?.computed_data.smr_left_average.toFixed(2),
    smr_average_right: session?.computed_data.smr_right_average.toFixed(2),
  };

  const maxRange = 0.9;
  const minRange = 0.7;
  const eventGraphRef = useRef(null);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);

  useEffect(() => {
    if (!eventGraphRef.current) return;
    renderEventGraphs(measurementValue, eventGraphRef);

    let tempLeft = [];
    let tempRight = [];
    // Transform raw data for graph
    ['theta', 'alpha', 'smr', 'beta'].forEach((band) => {
      tempLeft.push({
        featureName: band.toUpperCase(),
        'Eyes Open BL': session?.raw_data[band].left.eyes_open_baseline,
        'Eyes Closed BL': session?.raw_data[band].left.eyes_closed_baseline,
        'Math Test': session?.raw_data[band].left.math_task,
        'Recovery 1': session?.raw_data[band].left.recovery_1,
        Sounds: session?.raw_data[band].left.distracting_sounds_task,
        'Recovery 2': session?.raw_data[band].left.recovery_2,
        'HRV Task': session?.raw_data[band].left.hrv_task,
        'Recovery 3': session?.raw_data[band].left.recovery_3,
      });

      tempRight.push({
        featureName: band.toUpperCase(),
        'Eyes Open BL': session?.raw_data[band].right.eyes_open_baseline,
        'Eyes Closed BL': session?.raw_data[band].right.eyes_closed_baseline,
        'Math Test': session?.raw_data[band].right.math_task,
        'Recovery 1': session?.raw_data[band].right.recovery_1,
        Sounds: session?.raw_data[band].right.distracting_sounds_task,
        'Recovery 2': session?.raw_data[band].right.recovery_2,
        'HRV Task': session?.raw_data[band].right.hrv_task,
        'Recovery 3': session?.raw_data[band].right.recovery_3,
      });
    });

    setLeft(tempLeft);
    setRight(tempRight);
  }, [session, loading]);

  return (
    <>
      {loading ? (
        <LoadSpinner />
      ) : (
        <>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid xs={11}>
              <Typography variant="h5" style={{ fontWeight: 800 }}>
                Overall Brainscore
              </Typography>

              <Box marginTop="0.25rem">
                <NICounter niValue={measurementValue} />
              </Box>
            </Grid>
            <Grid xs={1}>
              <a
                href="https://youtu.be/7uBe-tHfI5k"
                rel="noreferrer"
                target="_blank"
              >
                <Tooltip title="Click to view the video tutorial.">
                  <IconButton color="primary" size="large">
                    <HelpIcon fontSize="inherit"/>
                  </IconButton>
                </Tooltip>
              </a>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid xs={12} md={6}>
              <Gauge
                min={0}
                max={100}
                value={measurementValue}
                maxAngle={90}
                minAngle={-90}
                tickCount={6}
                arcSegments={arcSegments}
                minRangeValue={minRange}
                maxRangeValue={maxRange}
              />
            </Grid>

            <Grid xs={12} md={6}>
              <EEGGraphic eventGraphRef={eventGraphRef} />
            </Grid>
          </Grid>

          <Grid container alignItems="center" spacing={2} sx={{ mt: 2 }}>
            <Grid xs={12} md={6}>
              <Typography align="center" variant={'h5'} style={{ fontStyle: 'italic' }}>
                IDEAL EEG Percentage of Frequency
              </Typography>

              <BrainScoreBarChart data={idealBrainScoreData} />
            </Grid>

            <Grid xs={12} md={6} sx={{ pl: 2 }}>
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Typography variant="h6" style={{ fontWeight: 600, marginBottom: '0.5rem' }}>
                  20% Theta:
                </Typography>

                <Typography variant="h6" style={{ marginBottom: '0.5rem' }}>
                  Light Sleep - Growth - Repair
                </Typography>
              </Stack>

              <Stack direction="row" justifyContent="center" spacing={2}>
                <Typography variant="h6" style={{ fontWeight: 600, marginBottom: '0.5rem' }}>
                  22% Alpha:
                </Typography>

                <Typography variant="h6" style={{ marginBottom: '0.5rem' }}>
                  Focus - Learning - Meditation
                </Typography>
              </Stack>

              <Stack direction="row" justifyContent="center" spacing={2}>
                <Typography variant="h6" style={{ fontWeight: 600, marginBottom: '0.5rem' }}>
                  7% SMR:
                </Typography>

                <Typography variant="h6" style={{ marginBottom: '0.5rem' }}>
                  Posture - Balance - Readiness
                </Typography>
              </Stack>

              <Stack direction="row" justifyContent="center" spacing={2}>
                <Typography variant="h6" style={{ fontWeight: 600, marginBottom: '0.5rem' }}>
                  30% Beta:
                </Typography>

                <Typography variant="h6" style={{ marginBottom: '0.5rem' }}>
                  Busy Brain - High Energy
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid xs={12} md={6}>
              <Typography align="center" variant={'h5'} style={{ fontStyle: 'italic' }}>
                YOUR EEG % of Frequency (Left Hemisphere)
              </Typography>

              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                mt={2}
                pl={8}
                pr={2}
              >
                <Typography variant="body1">Theta: {brainScoreData.theta_average_left}%</Typography>
                <Typography variant="body1">Alpha: {brainScoreData.alpha_average_left}%</Typography>
                <Typography variant="body1">SMR: {brainScoreData.smr_average_left}%</Typography>
                <Typography variant="body1">Beta: {brainScoreData.beta_average_left}%</Typography>
              </Stack>

              <BrainScoreBarChart data={left} />
            </Grid>

            <Grid justifyItems="center" xs={12} md={6}>
              <Typography align="center" variant={'h5'} style={{ fontStyle: 'italic' }}>
                YOUR EEG % of Frequency (Right Hemisphere)
              </Typography>

              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                mt={2}
                pl={8}
                pr={2}
              >
                <Typography variant="body1">
                  Theta: {brainScoreData.theta_average_right}%
                </Typography>

                <Typography variant="body1">
                  Alpha: {brainScoreData.alpha_average_right}%
                </Typography>

                <Typography variant="body1">SMR: {brainScoreData.smr_average_right}%</Typography>

                <Typography variant="body1">Beta: {brainScoreData.beta_average_right}%</Typography>
              </Stack>

              <BrainScoreBarChart data={right} />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default BrainScoreGraph;
