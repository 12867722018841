import { useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import SexSelect from '../../components/common/sexSelect';
import GenderSelect from '../../components/common/genderSelect';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment';
import validator from '../../common/validations.mjs';

export default function editClient({ client, processing, handleUpdate, handleCancel }) {
  const [firstName, setFirstName] = useState(client.firstName);
  const [lastName, setLastName] = useState(client.lastName);
  const [email, setEmail] = useState(client.email);
  const [sex, setSex] = useState(client.sex);
  const [gender, setGender] = useState(client.gender);
  const [dateOfBirth, setDateOfBirth] = useState(
    client.dateOfBirth === '' ? null : moment(client.dateOfBirth)
  );
  const [isDataUseAuthorized, setIsDataUseAuthorized] = useState(client.isDataUseAuthorized);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  function validate() {
    let valid = true;
    if (!validator().isName(firstName)) {
      setFirstNameError(true);
      valid = false;
    } else {
      setFirstNameError(false);
    }
    if (!validator().isName(lastName)) {
      setLastNameError(true);
      valid = false;
    } else {
      setLastNameError(false);
    }
    if (!validator().isEmail(email)) {
      setEmailError(true);
      valid = false;
    } else {
      setEmailError(false);
    }
    if (valid) {
      handleUpdate({
        clientId: client.clientId,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim(),
        sex: sex,
        gender: gender,
        dateOfBirth: dateOfBirth === null ? '' : dateOfBirth.format('YYYY-MM-DD'),
        isDataUseAuthorized: isDataUseAuthorized,
      });
    }
  }

  function resetForm() {
    setFirstName(client.firstName);
    setLastName(client.lastName);
    setEmail(client.email);
    setSex(client.sex);
    setGender(client.gender);
    setDateOfBirth(client.dateOfBirth === '' ? null : moment(client.dateOfBirth));
    setIsDataUseAuthorized(client.isDataUseAuthorized);
    if (handleCancel) {
      handleCancel();
    }
  }

  return (
    <Box component="form" noValidate>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined" error={firstNameError}>
          <InputLabel htmlFor="outlined-first-name">First Name</InputLabel>
          <OutlinedInput
            id="outlined-first-name"
            type="text"
            value={firstName}
            label="First Name"
            onChange={(event) => {
              setFirstName(event.target.value);
            }}
          />
          <FormHelperText>{firstNameError && 'This name is invalid.'}</FormHelperText>
        </FormControl>
      </div>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined" error={lastNameError}>
          <InputLabel htmlFor="outlined-last-name">Last Name</InputLabel>
          <OutlinedInput
            id="outlined-last-name"
            type="text"
            value={lastName}
            label="Last Name"
            onChange={(event) => {
              setLastName(event.target.value);
            }}
          />
          <FormHelperText>{lastNameError && 'This name is invalid.'}</FormHelperText>
        </FormControl>
      </div>
      <div>
        <FormControl fullWidth margin="normal" variant="outlined" error={emailError}>
          <InputLabel htmlFor="outlined-email">Email</InputLabel>
          <OutlinedInput
            id="outlined-email"
            type="text"
            value={email}
            label="Email"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <FormHelperText>{emailError && 'This email is invalid.'}</FormHelperText>
        </FormControl>
      </div>
      <div>
        <FormControl fullWidth margin="normal">
          <SexSelect sex={sex} setSex={setSex} />
        </FormControl>
      </div>
      <div>
        <FormControl fullWidth margin="normal">
          <GenderSelect gender={gender} setGender={setGender} />
        </FormControl>
      </div>
      <div>
        <FormControl fullWidth margin="normal">
          <DatePicker
            label="Date of Birth"
            value={dateOfBirth}
            onChange={(newValue) => setDateOfBirth(newValue)}
          />
        </FormControl>
      </div>
      <div>
        <FormControl fullWidth margin="normal">
          <FormControlLabel
            control={
              <Checkbox
                checked={isDataUseAuthorized}
                onChange={(event) => setIsDataUseAuthorized(event.target.checked)}
              />
            }
            label="Data Use Authorized by Client"
          />
        </FormControl>
      </div>
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
        <Button disabled={processing || null} variant="outlined" color="error" onClick={resetForm}>
          Cancel
        </Button>
        <Button disabled={processing || null} sx={{ ml: 1 }} variant="contained" onClick={validate}>
          Save
        </Button>
      </Box>
    </Box>
  );
}
