import { useRef, useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Label,
  LabelList,
  Legend,
  Cell,
  ReferenceLine,
  Tooltip as ChartTooltip,
} from 'recharts';
import { round } from 'lodash';
import {
  Box,
  Typography,
  Unstable_Grid2 as Grid,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  NICounter,
  arcSegments,
  renderEMGGraph,
  barGraphColorMapping,
  featureNames,
  CustomLegend,
  CustomTooltip,
} from '../../../components/organGraph/common';
import Gauge from './GaugeGraph';
import MuscleGraphic from '../../../components/organGraph/Muscles';
import { idealMuscleLeftData, idealMuscleRightData } from '../../../IdealData';
import LoadSpinner from '../../../components/LoadSpinner';
import HelpIcon from '@mui/icons-material/Help';

const MuscleBalanceChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        width={500}
        height={300}
        data={data}
        barGap={25}
        barWidth={45}
        margin={{
          top: 5,
          right: 100,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="title" tick={false} />
        <YAxis dx={-7} allowDecimals={true} style={{ textAnchor: 'middle' }} domain={[0, 4]}>
          <Label
            value="μVolts"
            position={'insideLeft'}
            style={{
              fill: 'rgba(0, 0, 0, 0.87)',
              textAnchor: 'middle',
            }}
            angle={-90}
          />
        </YAxis>
        <Bar dataKey={'value'} label="Label" barSize={40}>
          {featureNames.map((featureName, index) => (
            <Cell key={`cell-${index}`} fill={barGraphColorMapping[featureName]} />
          ))}
          <LabelList
            dataKey={'value'}
            position="top"
            formatter={(val) => round(val, 2)}
            style={{ fontSize: '14px' }}
          />
        </Bar>
        <ReferenceLine y={2.5} stroke="grey" strokeDasharray="3 3" ifOverflow="visible">
          <Label
            value="Max. Ideal (2.5)"
            position={'right'}
            style={{
              fontSize: '12px',
            }}
          />
        </ReferenceLine>
        <ReferenceLine y={0.5} stroke="grey" strokeDasharray="3 3">
          <Label
            value="Min. Ideal (0.5)"
            position={'right'}
            style={{
              fontSize: '12px',
            }}
          />
        </ReferenceLine>

        <Legend content={<CustomLegend />} />
        {/* <ChartTooltip
          content={<CustomTooltip type="sEMG" />}
          cursor={{ fill: 'rgb(128,128,128, 0.2)' }}
        /> */}
      </BarChart>
    </ResponsiveContainer>
  );
};

const MuscleGraph = ({ session, loading }) => {
  let measurementValueLeftTemp = session?.computed_data.sEMG_left * 100;
  const measurementValueLeft = measurementValueLeftTemp.toFixed(2);
  let measurementValueRightTemp = session?.computed_data.sEMG_right * 100;
  const measurementValueRight = measurementValueRightTemp.toFixed(2);

  const eventGraphRef = useRef();
  const maxRange = 0.9;
  const minRange = 0.7;

  const [valueL, setValueL] = useState([]);
  const [valueR, setValueR] = useState([]);
  useEffect(() => {
    if (!eventGraphRef.current) return;
    renderEMGGraph(measurementValueLeft, measurementValueRight, eventGraphRef);

    const tempL = [
      {
        title: 'Eyes Open BL',
        value: session?.raw_data.sEMG.left.eyes_open_baseline,
      },
      {
        title: 'Eyes Closed BL',
        value: session?.raw_data.sEMG.left.eyes_closed_baseline,
      },
      {
        title: 'Math Test',
        value: session?.raw_data.sEMG.left.math_task,
      },
      {
        title: 'Recovery 1',
        value: session?.raw_data.sEMG.left.recovery_1,
      },
      {
        title: 'Sounds',
        value: session?.raw_data.sEMG.left.distracting_sounds_task,
      },
      {
        title: 'Recovery 2',
        value: session?.raw_data.sEMG.left.recovery_2,
      },
      {
        title: 'HRV Task',
        value: session?.raw_data.sEMG.left.hrv_task,
      },
      {
        title: 'Recovery 3',
        value: session?.raw_data.sEMG.left.recovery_3,
      },
    ];

    const tempR = [
      {
        title: 'Eyes Open BL',
        value: session?.raw_data.sEMG.right.eyes_open_baseline,
      },
      {
        title: 'Eyes Closed BL',
        value: session?.raw_data.sEMG.right.eyes_closed_baseline,
      },
      {
        title: 'Math Test',
        value: session?.raw_data.sEMG.right.math_task,
      },
      {
        title: 'Recovery 1',
        value: session?.raw_data.sEMG.right.recovery_1,
      },
      {
        title: 'Sounds',
        value: session?.raw_data.sEMG.right.distracting_sounds_task,
      },
      {
        title: 'Recovery 2',
        value: session?.raw_data.sEMG.right.recovery_2,
      },
      {
        title: 'HRV Task',
        value: session?.raw_data.sEMG.right.hrv_task,
      },
      {
        title: 'Recovery 3',
        value: session?.raw_data.sEMG.right.recovery_3,
      },
    ];

    setValueL(tempL);
    setValueR(tempR);
  }, []);

  return (
    <>
      {loading ? (
        <LoadSpinner />
      ) : (
        <>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid xs={11}>
              <Typography variant="h5" style={{ fontWeight: 800 }}>
                sEMG Resilience Index
              </Typography>
              <Box
                marginTop="0.5rem"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="15rem"
              >
                <Typography variant="h6" style={{ fontWeight: 400, marginRight: '0.25rem' }}>
                  Left:
                </Typography>
                <NICounter niValue={measurementValueLeft} />
              </Box>
              <Box
                marginTop="0.25rem"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="15rem"
              >
                <Typography variant="h6" style={{ fontWeight: 400, marginRight: '0.25rem' }}>
                  Right:
                </Typography>
                <NICounter niValue={measurementValueRight} />
              </Box>
            </Grid>
            <Grid xs={1}>
              <a
                href="https://youtu.be/AJoCj3Rpb1c"
                rel="noreferrer"
                target="_blank"
              >
                <Tooltip title="Click to view the video tutorial.">
                  <IconButton color="primary" size="large">
                    <HelpIcon fontSize="inherit"/>
                  </IconButton>
                </Tooltip>
              </a>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid xs={12} md={5}>
              <Gauge
                min={0}
                max={100}
                value={measurementValueLeft}
                maxAngle={90}
                minAngle={-90}
                tickCount={6}
                arcSegments={arcSegments}
                minRangeValue={minRange}
                maxRangeValue={maxRange}
              />
            </Grid>
            <Grid xs={12} md={7}>
              <MuscleGraphic eventGraphRef={eventGraphRef} />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid xs={12}>
              <Typography align="center" variant={'h5'} style={{ fontStyle: 'italic' }}>
                Ideal Muscle Balance / sEMG (0.5-2.5 μVolts)
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography align="center" variant={'h6'}>
                Left{' '}
              </Typography>
              <MuscleBalanceChart data={idealMuscleLeftData} />
            </Grid>
            <Grid xs={12} md={6}>
              <Typography align="center" variant={'h6'}>
                Right{' '}
              </Typography>
              <MuscleBalanceChart data={idealMuscleRightData} />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid xs={12}>
              <Typography align="center" variant={'h5'} style={{ fontStyle: 'italic' }}>
                Your Muscle Balance (sEMG)
              </Typography>
            </Grid>
            <Grid xs={12} md={6}>
              <Typography align="center" variant={'h6'}>
                Left - {session?.computed_data.sEMG_left_average.toFixed(2)} μVolts
              </Typography>
              <MuscleBalanceChart data={valueL} />
            </Grid>
            <Grid xs={12} md={6}>
              <Typography align="center" variant={'h6'}>
                Right - {session?.computed_data.sEMG_right_average.toFixed(2)} μVolts
              </Typography>
              <MuscleBalanceChart data={valueR} />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default MuscleGraph;
