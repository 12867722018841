import * as d3 from 'd3';
import { ceil } from 'lodash';
import { Box, Typography, SvgIcon } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const brandedColorMapping = [
  '#ae0004',
  '#e70420',
  '#5bccfd',
  '#ee34fd',
  '#a9007c',
  '#2724fc',
  '#076e91',
  '#3ebaa6',
  '#00d385',
  '#69fb29',
];

const colorMapping = [
  'rgb(252, 30, 5)',
  'rgb(238, 94, 50)',
  'rgb(238, 112, 28)',
  'rgb(239, 145, 32)',
  'rgb(241, 164, 36)',
  'rgb(246, 199, 54)',
  'rgb(246, 202, 55)',
  'rgb(194, 205, 71)',
  'rgb(159, 206, 85)',
  'rgb(124, 230, 4)',
];

const arcSegments = [
  { min: 0, max: 0.1, color: colorMapping[0] },
  { min: 0.1, max: 0.2, color: colorMapping[1] },
  { min: 0.2, max: 0.3, color: colorMapping[2] },
  { min: 0.3, max: 0.4, color: colorMapping[3] },
  { min: 0.4, max: 0.5, color: colorMapping[4] },
  { min: 0.5, max: 0.6, color: colorMapping[5] },
  { min: 0.6, max: 0.7, color: colorMapping[6] },
  { min: 0.7, max: 0.8, color: colorMapping[7] },
  { min: 0.8, max: 0.9, color: colorMapping[8] },
  { min: 0.9, max: 1, color: colorMapping[9] },
];

const barGraphColorMapping = {
  'Eyes Open BL': brandedColorMapping[2],
  'Eyes Closed BL': 'lightblue',
  'Math Test': brandedColorMapping[9],
  'Recovery 1': 'lightblue',
  Sounds: brandedColorMapping[5],
  'Recovery 2': 'lightblue',
  'HRV Task': brandedColorMapping[3],
  'Recovery 3': 'lightblue',
};

const unitMapping = {
  HR: 'beats / min.',
  RR: 'breaths / min.',
  Skin: 'μSiemens',
  sEMG: 'μVolts',
  HandC: 'Degrees °C',
  HandF: 'Degrees °F',
};

const featureNames = [
  'Eyes Open BL',
  'Eyes Closed BL',
  'Math Test',
  'Recovery 1',
  'Sounds',
  'Recovery 2',
  'HRV Task',
  'Recovery 3',
];

const determineBrainScoreGrade = (score) => {
  if (score >= 98) {
    return 'A+';
  } else if (score >= 94) {
    return 'A';
  } else if (score >= 90) {
    return 'A-';
  } else if (score >= 87) {
    return 'B+';
  } else if (score >= 84) {
    return 'B';
  } else if (score >= 80) {
    return 'B-';
  } else if (score >= 77) {
    return 'C+';
  } else if (score >= 73) {
    return 'C';
  } else if (score >= 70) {
    return 'C-';
  } else if (score >= 67) {
    return 'D+';
  } else if (score >= 63) {
    return 'D';
  } else if (score >= 60) {
    return 'D-';
  } else {
    return 'NI';
  }
};

const NICounter = ({ niValue }) => {
  const grade = determineBrainScoreGrade(niValue);
  return (
    <Box style={{ border: '1px solid grey' }} display="flex" alignItems="center" width="10rem">
      <Box
        width="65%"
        style={{ borderRight: '1px solid grey' }}
        textAlign="center"
        padding="0.5rem"
      >
        <Typography variant="h6" fontWeight={600}>
          {niValue}%
        </Typography>
      </Box>
      <Box width="35%" padding="0.5rem">
        <Typography variant="h6" fontWeight={600} style={{ color: 'red' }} textAlign="center">
          {grade}
        </Typography>
      </Box>
    </Box>
  );
};

const CustomLegend = (props) => {
  return (
    <Box
      position="relative"
      display={'flex'}
      flexDirection={'column'}
      width={'100%'}
      alignItems={'center'}
      top={'0.5rem'}
      fontFamily={'Roboto'}
      marginLeft="3rem"
    >
      <Box
        position="relative"
        display={'flex'}
        flexDirection={'row'}
        width={'100%'}
        justifyContent={'space-around'}
        fontFamily={'Roboto'}
        textAlign="center"
      >
        {featureNames.slice(0, 4).map((featureName) => (
          <Box display={'flex'} flexDirection={'row'} key={`${featureName}-bar-key`} width="25%">
            <SvgIcon fontSize="small" style={{ color: barGraphColorMapping[featureName] }}>
              <FiberManualRecordIcon />
            </SvgIcon>
            <Typography fontSize="small">{featureName}</Typography>
          </Box>
        ))}
      </Box>
      <Box
        position="relative"
        display={'flex'}
        flexDirection={'row'}
        width={'100%'}
        justifyContent={'space-around'}
        fontFamily={'Roboto'}
        marginTop="0.5rem"
      >
        {featureNames.slice(4, 8).map((featureName) => (
          <Box display={'flex'} flexDirection={'row'} key={`${featureName}-bar-key`} width="25%">
            <SvgIcon fontSize="small" style={{ color: barGraphColorMapping[featureName] }}>
              <FiberManualRecordIcon />
            </SvgIcon>
            <Typography fontSize="small">{featureName}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const CustomTooltip = ({ payload, type }) => {
  const xAxisLabel =
    payload && payload.length > 0 && payload[0]?.payload && payload[0]?.payload.title
      ? payload[0]?.payload.title
      : '';

  const value =
    payload && payload.length > 0 && payload[0]?.payload && payload[0]?.payload.value
      ? payload[0]?.payload.value
      : '';

  return (
    <Box
      style={{
        backgroundColor: 'white',
        padding: '1rem',
        outline: '1px solid black',
      }}
      display="flex"
      flexDirection="row"
      justifyContent={'space-between'}
      width="20rem"
    >
      <Typography variant="body1" color={barGraphColorMapping[xAxisLabel]}>
        {xAxisLabel}:
      </Typography>
      <Typography variant="body1">
        {value} {unitMapping[type] || ''}
      </Typography>
    </Box>
  );
};

const FullLinearGradient = () => {
  return (
    <defs>
      <linearGradient id="fullGradient" x1="0%" x2="0%" y1="100%" y2="0%">
        <stop offset="0%" style={{ stopColor: colorMapping[0], stopOpacity: 1 }}></stop>
        <stop offset="10%" style={{ stopColor: colorMapping[0], stopOpacity: 1 }}></stop>
        <stop offset="10%" style={{ stopColor: colorMapping[1], stopOpacity: 1 }}></stop>
        <stop offset="20%" style={{ stopColor: colorMapping[1], stopOpacity: 1 }}></stop>
        <stop offset="20%" style={{ stopColor: colorMapping[2], stopOpacity: 1 }}></stop>
        <stop offset="30%" style={{ stopColor: colorMapping[2], stopOpacity: 1 }}></stop>
        <stop offset="30%" style={{ stopColor: colorMapping[3], stopOpacity: 1 }}></stop>
        <stop offset="40%" style={{ stopColor: colorMapping[3], stopOpacity: 1 }}></stop>
        <stop offset="40%" style={{ stopColor: colorMapping[4], stopOpacity: 1 }}></stop>
        <stop offset="50%" style={{ stopColor: colorMapping[4], stopOpacity: 1 }}></stop>
        <stop offset="50%" style={{ stopColor: colorMapping[5], stopOpacity: 1 }}></stop>
        <stop offset="60%" style={{ stopColor: colorMapping[6], stopOpacity: 1 }}></stop>
        <stop offset="60%" style={{ stopColor: colorMapping[6], stopOpacity: 1 }}></stop>
        <stop offset="70%" style={{ stopColor: colorMapping[7], stopOpacity: 1 }}></stop>
        <stop offset="70%" style={{ stopColor: colorMapping[7], stopOpacity: 1 }}></stop>
        <stop offset="80%" style={{ stopColor: colorMapping[8], stopOpacity: 1 }}></stop>
        <stop offset="80%" style={{ stopColor: colorMapping[8], stopOpacity: 1 }}></stop>
        <stop offset="90%" style={{ stopColor: colorMapping[9], stopOpacity: 1 }}></stop>
        <stop offset="90%" style={{ stopColor: colorMapping[9], stopOpacity: 1 }}></stop>
        <stop offset="100%" style={{ stopColor: colorMapping[9], stopOpacity: 1 }}></stop>
      </linearGradient>
      <marker
        id="arrow"
        markerUnits="strokeWidth"
        markerWidth="12"
        markerHeight="12"
        viewBox="0 0 12 12"
        refX="6"
        refY="6"
        orient="auto-start-reverse"
        fill="navy"
      >
        <path d="M2,2 L6,6 L2,10 L10,6 L2,2" />
      </marker>
    </defs>
  );
};

const renderEventGraphs = (measurementValue, eventGraphRef, type = null) => {
  const svg = d3.select(eventGraphRef.current);

  const lg = svg
    .append('defs')
    .append('linearGradient')
    .attr('id', 'mygrad') //id of the gradient
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '100%')
    .attr('y2', '0%'); //since its a vertical linear gradient

  const numLevels = ceil(measurementValue / 10);

  //create linear gradient based on provided value
  for (let i = 0; i < numLevels; i++) {
    //add an entire new band
    if (i + 1 < numLevels) {
      lg.append('stop')
        .attr('offset', `${i * 10}%`)
        .style('stop-color', colorMapping[i])
        .style('stop-opacity', 1);

      lg.append('stop')
        .attr('offset', `${(i + 1) * 10}%`)
        .style('stop-color', colorMapping[i])
        .style('stop-opacity', 1);
    }
    //add partial remainder band
    else {
      lg.append('stop')
        .attr('offset', `${i * 10}%`)
        .style('stop-color', colorMapping[i])
        .style('stop-opacity', 1);
      lg.append('stop')
        .attr('offset', `${i * 10 + (measurementValue % 10 === 0 ? 10 : measurementValue % 10)}%`)
        .style('stop-color', colorMapping[i])
        .style('stop-opacity', 1);
      lg.append('stop')
        .attr('offset', `${i * 10 + (measurementValue % 10 === 0 ? 10 : measurementValue % 10)}%`)
        .style('stop-color', 'white')
        .style('stop-opacity', 0);
      lg.append('stop')
        .attr('offset', '100%')
        .style('stop-color', 'white')
        .style('stop-opacity', 0);
    }
  }

  lg.append('animate')
    .attr('attributeName', 'y2')
    .attr('from', `100%`)
    .attr('to', '0%')
    .attr('dur', '2s')
    .attr('begin', '0ms')
    .attr(
      'keySplines',
      '0.1 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1'
    );

  //add scale and color
  const scale = d3.scaleLinear().domain([100, 0]).range([0, 435]);
  const axis = d3.axisRight(scale);

  svg.select('#axis_g').call(axis);
  svg.select('#axis_g').select('path').attr('stroke', 'url(#fullGradient)');
  svg.select('#axis_g').selectAll('text').attr('font-size', '1rem');
  //remove scale tick lines
  svg.select('#axis_g').selectAll('line').remove();

  if (type !== 'handTemp') {
    svg
      .select('#axis_g')
      .append('line')
      .style('stroke', 'navy')
      .attr('stroke-width', 3)
      .attr('x1', -12)
      .attr('y1', 435 - (measurementValue * 435) / 100)
      .attr('x2', -535)
      .attr('y2', 435 - (measurementValue * 435) / 100)
      .attr('marker-start', 'url(#arrow)');
  } else {
    svg
      .select('#axis_g')
      .append('line')
      .style('stroke', 'navy')
      .attr('stroke-width', 3)
      .attr('x1', -12)
      .attr('y1', 435 - (measurementValue * 435) / 100)
      .attr('x2', -230)
      .attr('y2', 435 - (measurementValue * 435) / 100)
      .attr('marker-start', 'url(#arrow)');
  }
};

const renderHeartGraph = (measurementValue, eventGraphRef) => {
  const svg = d3.select(eventGraphRef.current);

  const lg = svg
    .append('defs')
    .append('linearGradient')
    .attr('id', 'heartGrad') //id of the gradient
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '100%')
    .attr('y2', '0%'); //since its a vertical linear gradient

  const numLevels = measurementValue > 50 ? 5 : ceil(measurementValue / 10) - 1;

  //create linear gradient based on provided value
  for (let i = 0; i < numLevels; i++) {
    //add an entire new band
    if (i + 1 < numLevels) {
      lg.append('stop')
        .attr('offset', `${i * 21}%`)
        .style('stop-color', colorMapping[i + 1])
        .style('stop-opacity', 1);

      lg.append('stop')
        .attr('offset', `${(i + 1) * 21}%`)
        .style('stop-color', colorMapping[i + 1])
        .style('stop-opacity', 1);
    }
    //add partial remainder band
    else {
      lg.append('stop')
        .attr('offset', `${i * 21}%`)
        .style('stop-color', colorMapping[i + 1])
        .style('stop-opacity', 1);
      lg.append('stop')
        .attr(
          'offset',
          `${i * 21 + (measurementValue % 10 === 0 ? 21 : ((measurementValue % 10) / 10) * 21)}%`
        )
        .style('stop-color', colorMapping[i + 1])
        .style('stop-opacity', 1);
      lg.append('stop')
        .attr(
          'offset',
          `${i * 21 + (measurementValue % 10 === 0 ? 21 : ((measurementValue % 10) / 10) * 21)}%`
        )
        .style('stop-color', 'white')
        .style('stop-opacity', 0);
      lg.append('stop')
        .attr('offset', '100%')
        .style('stop-color', 'white')
        .style('stop-opacity', 0);
    }
  }

  lg.append('animate')
    .attr('attributeName', 'y2')
    .attr('from', `100%`)
    .attr('to', '0%')
    .attr('dur', '2s')
    .attr('begin', '0ms')
    .attr(
      'keySplines',
      '0.1 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1'
    );
};

const renderEMGGraph = (measurementValueLeft, measurementValueRight, eventGraphRef) => {
  const svg = d3.select(eventGraphRef.current);

  //add scale and color
  const scale = d3.scaleLinear().domain([100, 0]).range([0, 435]);
  const axis = d3.axisRight(scale);

  svg.select('#axis_g').call(axis);
  svg.select('#axis_g').attr('transform', 'translate(540, 52)');
  svg.select('#axis_g').select('path').attr('stroke', 'url(#fullGradient)');
  svg.select('#axis_g').selectAll('text').attr('font-size', '1rem');
  //remove scale tick lines
  svg.select('#axis_g').selectAll('line').remove();

  [measurementValueLeft, measurementValueRight].forEach((measurementValue, index) => {
    const lg = svg
      .append('defs')
      .append('linearGradient')
      .attr('id', index === 0 ? 'mygradLeft' : 'mygradRight') //id of the gradient
      .attr('x1', '0%')
      .attr('x2', '0%')
      .attr('y1', '100%')
      .attr('y2', '0%'); //since its a vertical linear gradient

    const numLevels = ceil(measurementValue / 10);

    //create linear gradient based on provided value
    for (let i = 0; i < numLevels; i++) {
      //add an entire new band
      if (i + 1 < numLevels) {
        lg.append('stop')
          .attr('offset', `${i * 10}%`)
          .style('stop-color', colorMapping[i])
          .style('stop-opacity', 1);

        lg.append('stop')
          .attr('offset', `${(i + 1) * 10}%`)
          .style('stop-color', colorMapping[i])
          .style('stop-opacity', 1);
      }
      //add partial remainder band
      else {
        lg.append('stop')
          .attr('offset', `${i * 10}%`)
          .style('stop-color', colorMapping[i])
          .style('stop-opacity', 1);
        lg.append('stop')
          .attr('offset', `${i * 10 + (measurementValue % 10 === 0 ? 10 : measurementValue % 10)}%`)
          .style('stop-color', colorMapping[i])
          .style('stop-opacity', 1);
        lg.append('stop')
          .attr('offset', `${i * 10 + (measurementValue % 10 === 0 ? 10 : measurementValue % 10)}%`)
          .style('stop-color', 'white')
          .style('stop-opacity', 0);
        lg.append('stop')
          .attr('offset', '100%')
          .style('stop-color', 'white')
          .style('stop-opacity', 0);
      }
    }

    lg.append('animate')
      .attr('attributeName', 'y2')
      .attr('from', `100%`)
      .attr('to', '0%')
      .attr('dur', '2s')
      .attr('begin', '0ms')
      .attr(
        'keySplines',
        '0.1 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1;0.1 0.8 0.2 1'
      );

    svg
      .select('#axis_g')
      .append('line')
      .style('stroke', 'navy')
      .attr('stroke-width', 3)
      .attr('x1', -12)
      .attr('y1', 435 - (measurementValue * 435) / 100)
      .attr('x2', -535)
      .attr('y2', 435 - (measurementValue * 435) / 100)
      .attr('marker-start', 'url(#arrow)');
  });
};

export {
  FullLinearGradient,
  NICounter,
  colorMapping,
  arcSegments,
  renderEventGraphs,
  renderEMGGraph,
  renderHeartGraph,
  barGraphColorMapping,
  featureNames,
  CustomLegend,
  CustomTooltip,
};
