import { useRef, useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Legend,
  ResponsiveContainer,
  Label,
  LabelList,
  Cell,
  ReferenceLine,
} from 'recharts';
import { round } from 'lodash';
import {
  Box,
  Typography,
  Unstable_Grid2 as Grid,
  IconButton,
  Tooltip,
} from '@mui/material';
import Gauge from './GaugeGraph';
import {
  NICounter,
  arcSegments,
  renderEventGraphs,
  featureNames,
  barGraphColorMapping,
  CustomLegend,
  CustomTooltip,
} from '../../../components/organGraph/common';
import HandGraphic from '../../../components/organGraph/Hand';
import { idealSkinData } from '../../../IdealData';
import LoadSpinner from '../../../components/LoadSpinner';
import HelpIcon from '@mui/icons-material/Help';

const SkinConductanceChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        width={500}
        height={300}
        data={data}
        barGap={25}
        barWidth={45}
        margin={{
          top: 5,
          right: 100,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="title" tick={false} />
        <YAxis dx={-16} style={{ textAnchor: 'middle' }} domain={[0, 2.5]}>
          <Label
            value="μSiemens"
            position={'insideLeft'}
            style={{
              fill: 'rgba(0, 0, 0, 0.87)',
              textAnchor: 'middle',
            }}
            angle={-90}
          />
        </YAxis>
        <Bar dataKey={'value'} label="Label" barSize={40}>
          {featureNames.map((featureName, index) => (
            <Cell key={`cell-${index}`} fill={barGraphColorMapping[featureName]} />
          ))}
          <LabelList
            dataKey={'value'}
            position="top"
            formatter={(val) => round(val, 2)}
            style={{ fontSize: '14px' }}
          />
        </Bar>
        <ReferenceLine y={1.5} stroke="grey" strokeDasharray="3 3" ifOverflow="visible">
          <Label
            value="Max. Ideal (1.5)"
            position={'right'}
            style={{
              fontSize: '12px',
            }}
          />
        </ReferenceLine>
        <ReferenceLine y={0.8} stroke="grey" strokeDasharray="3 3">
          <Label
            value="Min. Ideal (0.8)"
            position={'right'}
            style={{
              fontSize: '12px',
            }}
          />
        </ReferenceLine>

        <Legend content={<CustomLegend />} />
        {/* <ChartTooltip
          content={<CustomTooltip type="Skin" />}
          cursor={{ fill: 'rgb(128,128,128, 0.2)' }}
        /> */}
      </BarChart>
    </ResponsiveContainer>
  );
};

const SkinGraph = ({ session, loading }) => {
  let tempIndex = session?.computed_data.skin_conductance_resilence_index * 100;
  const measurementValue = tempIndex.toFixed(2);
  const eventGraphRef = useRef();
  const maxRange = 0.85;
  const minRange = 0.5;
  const [value, setValue] = useState([]);
  useEffect(() => {
    if (!eventGraphRef.current) return;
    renderEventGraphs(measurementValue, eventGraphRef);
    const temp = [
      {
        title: 'Eyes Open BL',
        value: session?.raw_data.gsr.eyes_open_baseline,
      },
      {
        title: 'Eyes Closed BL',
        value: session?.raw_data.gsr.eyes_closed_baseline,
      },
      {
        title: 'Math Test',
        value: session?.raw_data.gsr.math_task,
      },
      {
        title: 'Recovery 1',
        value: session?.raw_data.gsr.recovery_1,
      },
      {
        title: 'Sounds',
        value: session?.raw_data.gsr.distracting_sounds_task,
      },
      {
        title: 'Recovery 2',
        value: session?.raw_data.gsr.recovery_2,
      },
      {
        title: 'HRV Task',
        value: session?.raw_data.gsr.hrv_task,
      },
      {
        title: 'Recovery 3',
        value: session?.raw_data.gsr.recovery_3,
      },
    ];
    setValue(temp);
  }, []);

  return (
    <>
      {loading ? (
        <LoadSpinner />
      ) : (
        <>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid xs={11}>
              <Typography variant="h5" style={{ fontWeight: 800 }}>
                Skin Conductance Resilience Index
              </Typography>
              <Box marginTop="0.25rem">
                <NICounter niValue={measurementValue} />
              </Box>
            </Grid>
            <Grid xs={1}>
              <a
                href="https://youtu.be/vsRoBy2H09I"
                rel="noreferrer"
                target="_blank"
              >
                <Tooltip title="Click to view the video tutorial.">
                  <IconButton color="primary" size="large">
                    <HelpIcon fontSize="inherit"/>
                  </IconButton>
                </Tooltip>
              </a>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid xs={12} md={6}>
              <Gauge
                min={0}
                max={100}
                value={measurementValue}
                maxAngle={90}
                minAngle={-90}
                tickCount={6}
                arcSegments={arcSegments}
                minRangeValue={minRange}
                maxRangeValue={maxRange}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <HandGraphic eventGraphRef={eventGraphRef} />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid xs={12} md={6}>
              <Typography align="center" variant={'h5'} style={{ fontStyle: 'italic' }}>
                IDEAL Skin Conductance (0.8 - 1.5 μSiemens)
              </Typography>
              <SkinConductanceChart data={idealSkinData} />
            </Grid>
            <Grid xs={12} md={6}>
              <Typography align="center" variant={'h5'} style={{ fontStyle: 'italic' }}>
                YOUR Skin Conductance - {session?.computed_data.skin_conductance_average.toFixed(2)}{' '}
                μSiemens
              </Typography>
              <SkinConductanceChart data={value} />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default SkinGraph;
